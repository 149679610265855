/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {},
      finalize: function() {
        $('.menuAccordion').find('.collapse').on('show.bs.collapse', function () {
          $(this).prev().addClass('active-acc');
        });
        $('.menuAccordion').find('.collapse').on('hide.bs.collapse', function () {
            $(this).prev().removeClass('active-acc');
        });
      }
    },
    // Home page
    'home': {
      init: function() {},
      finalize: function() {
        $(".coverage-slider__slides").slick({
          slidesToShow: 4,
          slidesToScroll: 4,
          prevArrow:$('.coverage-slider__arrows .slick-prev'),
          nextArrow:$('.coverage-slider__arrows .slick-next'),
          dots: false,
          fade: false,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }
          ]
        });

        $(".random-faq__slides").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          fade: false,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 5000,
          adaptiveHeight: true,
          speed: 500,
          vertical: true,
          verticalSwiping: true
        });

        var resizeTimeout;
        $(window).resize(function(){
          if(!!resizeTimeout){ clearTimeout(resizeTimeout); }
          resizeTimeout = setTimeout(function(){
            var maxHeight = -1;
            $('.random-faq__slides .slick-slide').each(function() {
              if ($(this).height() > maxHeight) {
                maxHeight = $(this).height();
              }
            });
            $('.random-faq__slides .slick-slide').each(function() {
              if ($(this).height() < maxHeight) {
                $(this).css('margin', Math.ceil((maxHeight-$(this).height())/2) + 'px 0');
              }
            });
          },100);
        });


        var maxHeight = -1;
        $('.random-faq__slides .slick-slide').each(function() {
          if ($(this).height() > maxHeight) {
            maxHeight = $(this).height();
          }
        });
        $('.random-faq__slides .slick-slide').each(function() {
          if ($(this).height() < maxHeight) {
            $(this).css('margin', Math.ceil((maxHeight-$(this).height())/2) + 'px 0');
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
